import React from 'react'
import Banner from './layouts/Banner'
import ContactUs from './layouts/ContactUs'
import Gallery from './layouts/Gallery'
import Reviews from './layouts/Reviews'
import Header from './components/Header'
import Footer from './components/Footer'
import About from './layouts/About';


function Main() {

    return (
        <>
            <Header/>
            <div className="mainWrapper">
                <Banner/>
                <Gallery/>
                <About/>
                <Reviews/>
                <ContactUs/>
            </div>
            <Footer/>
        </>
    )
}

export default Main