import React, {useState} from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import data from '../../data/locData';
import Modal from "react-modal";

Modal.setAppElement("#root");
const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ["<img src='./img/arrowLeft.svg'>", "<img src='./img/arrowRight.svg'>"],
    responsiveClass: true,
    responsive: {
        0: {
            items: 2,
            nav: true
        },
        600: {
            items: 3,
            nav: false
        },
        1000: {
            items: 4,
            nav: true,
            loop: true
        }
    }
};

function Gallery() {
    const gallery = data.location.gallery
    const listItemStyle = data.settings
    const [isOpen, setIsOpen] = useState(false);
    const [openImg, setOpenImg] = useState("");

    function toggleModal(i) {
        if (i !== null) {
            console.log(i)
            setOpenImg(openImg => gallery[i].original);
        }
        setIsOpen(isOpen => !isOpen);
    }

    return (
        <>
            <div id="gallery" className="gallerSlider displaySection" style={{
                background: listItemStyle.bggradient
            }}>
                <div className="container">
                    <div className="gallerSlide">
                        <h3>Gallery</h3>

                        <OwlCarousel id="gallerySlider" className='owl-theme'{...options}>
                            {
                                gallery.map((item, i) => {
                                    return (
                                        <button key={i} onClick={() => toggleModal(i)}>
                                            <div className="item">
                                                <img className='img-fluid' src={item.thumbnail} alt="gallery"/>
                                            </div>
                                        </button>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>


            <Modal
                isOpen={isOpen}
                onRequestClose={() => toggleModal(null)}
                contentLabel="My dialog"
            >
                <img src={openImg} alt="gallery"/>
                <button className='close__model' onClick={() => toggleModal(null)}><i className="fas fa-times"></i></button>
            </Modal>
        </>
    )
}

export default Gallery

