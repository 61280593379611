import React from 'react'
import {Link} from "react-router-dom";
import data from '../../data/locData';

function Footer() {
    const location = data.location
    const social_media = location.social_media
    const listItemStyle = data.settings
    const year = new Date().getFullYear()
    return (
        <>
            <footer className="displaySection">
                <div className="container">
                    <div className="rowFlex">
                        <div className="footerBrand fooColumn">
                            <Link to="#"><img src={location.logo_footer} alt="Logo" width="147.59"/></Link>
                        </div>
                        <div className="socialLink fooColumn">
                            <span>{social_media.length === 0 ? "" : "Connect with us"}</span>
                            <>
                                {
                                    social_media.map((item, i) => {
                                        return (
                                            <a key={i} href={item.link} target="_blank" rel="noreferrer">
                                                <img src={item.icon} alt="Social Icon"/>
                                            </a>
                                        )
                                    })
                                }
                            </>
                        </div>
                        <div className="copyWrite fooColumn">
                            <p>Powered by
                                <a href="https://www.kloudeats.com"
                                   style={{color: listItemStyle.primaryColor}}> Kloudeats
                                </a>. Copyright © {year} <em>{location.name}</em>. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer