import React from 'react'
import data from '../../data/locData';

function Banner() {
    const banner = data.location.banner
    const listItemStyle = data.settings

    return (
        <>
            <div id="orderonline" className="heroBanner displaySection">
                <div className="container">
                    <div className="rowFlex">
                        <div className="leftText">
                            <h1 style={{color: listItemStyle.primaryColor}}>{banner.title}</h1>
                            <p>{banner.description}</p>
                            <a className={banner.button_link === "" ? "hide" : ""}
                               style={{
                                   background: listItemStyle.primaryColor,
                                   boxShadow: listItemStyle.boxShadow
                               }}
                               href={banner.button_link}>{banner.button_name}
                            </a>
                        </div>
                        <div className="leftImg">
                            <img src={banner.image} alt="bannerImg"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner
