const data = {
    "settings": {
        "template_id": 1,
        "primaryColor": "#ff7c00",
        "darkColor": "#000000",
        "boxShadow": "0px 30px 80px 5px rgba(209, 43, 40, 0.3)",
        "bggradient": "linear-gradient(180deg, rgba(209, 43, 40, 0.09) 0%, rgba(255, 255, 255, 0.11) 100%)",
        "contactAddress": "linear-gradient(98.63deg, #FFF8F8 0%, rgba(255, 255, 255, 0) 100%)",
        "commonStyleBox": "linear-gradient(98.63deg, #FFF8F8 0%, rgba(255, 255, 255, 0) 100%)",

    },
    "location": {
        "id": 156,
        "name": "JC Wings & Fish",
        "category": "Fastfood",
        "phone": 2234560789,
        "url": "http://127.0.0.1:5500/index.html",
        "address": "",
        "logo": "./img/v1/logo.png",
        "logo_footer": "./img/v1/logo.png",
        "banner": {
            "title": "JC Wings & Fish",
            "description": "At JC Wings and Fish, we always strive to give you a first-class experience.\nEnjoy delicious food from our restaurant today.",
            "image": "./img/v1/hero.png",
            "thumbnail": "",
            "button_name": "Order Online",
            "button_link": "https://order-uat.kloudeats.com/l/jc-wings-and-fish/174"
        },
        "gallery": [
            {
                "original": "./img/v1/gallery/gallery1.jpg",
                "thumbnail": "./img/v1/gallery/gallery1-thumbnail.jpg"
            },
            {
                "original": "./img/v1/gallery/gallery2.jpg",
                "thumbnail": "./img/v1/gallery/gallery2-thumbnail.jpg"
            },
            {
                "original": "./img/v1/gallery/gallery3.jpg",
                "thumbnail": "./img/v1/gallery/gallery3-thumbnail.jpg"
            },
            {
                "original": "./img/v1/gallery/gallery4.jpg",
                "thumbnail": "./img/v1/gallery/gallery4-thumbnail.jpg"
            },
        ],
        "about": [
            {
                "img": "./img/v1/about.png",
                "title": "Our Story",
                "description": "JC Wings and Fish is where you’ll find the best seafood and fried delicious! We put heart and soul into our seafood, and our best-kept secret is our irresistible batter and special seasoning recipes. Come join us for a wonderful dining experience.",
            },
            {
                "img": "./img/v1/about.png",
                "title": "Our Food",
                "description": "You can find some of the best-tasting fried foods at JC Wings and Fish. We also have options for everyone. On our menu, you'll find mouthwatering fish fillets, fried wings, tender catfish, hush puppies, and even fried oysters. Additionally, JC Fish and Wings serves delectable fried shrimp with fries and a huge range of fish fillets. Rounding out this menu is a complete range of fried seafood including fish, shrimp, oysters, fries, cheese sticks, hush puppies, onion rings, fried okra, and a variety of soft drinks! And best of all, everything is DELICIOUS!",
            }
        ],
        "reviews": {
            "image": "./img/v1/review.png",
            "contents": [
                {
                    "review": "This place is the hidden gem 💎. The food is always good.",
                    "reviewer": "",
                },
                {
                    "review": "The fish and chips were delicious with a portion so large.",
                    "reviewer": "",
                },
                {
                    "review": " Always value for money. Loved their fried shrimp.",
                    "reviewer": "",
                },
                {
                    "review": "The food is always good..hot and fresh. My all-time favorite place for fried seafood and wings.",
                    "reviewer": "",
                },
                {
                    "review": "I ordered wings and fries. And it turned out really good.",
                    "reviewer": "",
                }
            ]
        },
        "social_media": [
            {
                "icon": "./img/social_icon/facebook.png",
                "link": "https://www.facebook.com/profile.php?id=61552644387281"
            },
            {
                "icon": "./img/social_icon/instagram.png",
                "link": "https://instagram.com/kloudeats"
            },
            {
                "icon": "./img/social_icon/twitter.png",
                "link": "https://twitter.com/kloudeats"
            },
            {
                "icon": "./img/social_icon/youtube.png",
                "link": "https://www.youtube.com/@KloudEats"
            },
        ],
        "business_hours": "Monday\t\t\t\t" +
            "10AM - 9:30PM\n" +
            "Tuesday\t\t\t\t" +
            "10AM - 9:30PM\n" +
            "Wednesday\t\t\t" +
            "10AM - 9:30PM\n" +
            "Thursday\t\t\t" +
            "10AM - 9:30PM\n" +
            "Friday\t\t\t\t" +
            "10AM - 9:30PM\n" +
            "Saturday\t\t\t" +
            "10AM - 9:30PM\n" +
            "Sunday\t\t\t\t" +
            "Closed - Closed"
    }
}
export default data