import React, {useEffect} from 'react'
import {Routes, Route} from "react-router-dom"
import './assets/css/style.css'
import Main from './main/Main';
import data from "./data/locData";

function App() {
    useEffect(() => {
        document.title = data.location.name;
    }, []);
    return (
        <>
            <Routes>
                <Route index element={<Main/>}/>
            </Routes>
        </>
    );
}

export default App;
