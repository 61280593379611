import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import data from '../../data/locData';

const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ["<img src='./img/arrowLeft.svg'>", "<img src='./img/arrowRight.svg'>"],
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
            nav: true
        },
        600: {
            items: 1,
            nav: false
        },
        1000: {
            items: 1,
            nav: true,
            loop: true
        }
    }
};

function Reviews() {
    const reviews = data.location.reviews
    const listItemStyle = data.settings
    return (
        <>
            <div id="reviews" className="review displaySection" style={{
                background: listItemStyle.bggradient
            }}>
                <div className="container">
                    <div className='leftreviewText'>
                        <h5 style={{
                            color: listItemStyle.primaryColor
                        }}>Reviews</h5>
                        <h4>that's what our client says </h4>
                        <OwlCarousel id="reviewSlider" className='owl-carousel owl-theme'{...options}>
                            {

                                reviews.contents.map((item, i) => {
                                    return (
                                        <div className="item" key={i}>
                                            <p>{item.review}</p>
                                            <span>{item.reviewer}</span>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                    <div className="imageReview">
                        <img src={reviews.image} alt="Reviews"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reviews