import React, {useEffect} from 'react'
import {Link} from "react-router-dom";
import Nav from './Nav'
import data from '../../data/locData';


function Header() {

    const header = data.location
    const listItemStyle = data.settings
    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    useEffect(() => {
        // scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        scrollTop >= 0 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };
    return (
        <>
            <header className="header v1_head header-section">
                <div className="container">
                    <div className="rowFlex">
                        <div className="brand">
                            <Link to="#" onClick={() => {
                                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                            }}><img src={header.logo} alt="Logo"/></Link>
                        </div>
                        <Nav/>
                    </div>
                </div>
            </header>
            <div className="mobileMenu">
                <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu"/>
                <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                    <div className="spinner diagonal part-1" style={{
                        background: listItemStyle.primaryColor
                    }}></div>
                    <div className="spinner horizontal" style={{
                        background: listItemStyle.primaryColor
                    }}></div>
                    <div className="spinner diagonal part-2" style={{
                        background: listItemStyle.primaryColor
                    }}></div>
                </label>
                <div id="sidebarMenu">
                    <Nav/>
                </div>
            </div>

        </>
    )
}

export default Header