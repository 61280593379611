import React from 'react'
import data from '../../data/locData';

function About() {
    const about = data.location.about
    const listItemStyle = data.settings
    return (
        <>
            <div id="generalSection" className="generalSection v8__promo  displaySection">
                <div className="container">
                    <div className="colrowWrap">
                        <div className="rowFlex">
                            {
                                about.map((item, i) => {
                                    return (
                                        <div className="generalFlex" key={i}>
                                            <div className="leftBox">
                                                <div className="headTitle">
                                                    <h3 style={{color: listItemStyle.primaryColor}}>{item.title}</h3>
                                                    <p style={{color: listItemStyle.darkColor}}>{item.description}</p>
                                                </div>
                                            </div>
                                            <div className="rightBox">
                                                <img src={item.img} alt="cooks-entertains"/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About