import React from 'react'
import {Link} from "react-scroll";
import data from '../../data/locData';

function Nav() {
    const listItemStyle = data.settings
    const banner = data.location.banner;

    document.documentElement.style
        .setProperty('--primaryColor', listItemStyle.primaryColor);

    return (
        <>
            <nav>
                <ul>
                    <li><Link
                        activeClass="active"
                        to="root"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >Home<span style={{
                        background: listItemStyle.primaryColor
                    }}></span></Link></li>
                    <li className={banner.button_link === "" ? "hide" : ""}><Link
                        activeClass="active"
                        to="orderonline"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >{banner.button_name}<span style={{
                        background: listItemStyle.primaryColor
                    }}></span></Link></li>
                    <li><Link
                        activeClass="active"
                        to="gallery"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >Gallery<span style={{
                        background: listItemStyle.primaryColor
                    }}></span></Link></li>
                    <li><Link
                        activeClass="active"
                        to="generalSection"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >About<span style={{
                        background: listItemStyle.primaryColor
                    }}></span></Link></li>
                    <li><Link
                        activeClass="active"
                        to="reviews"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >Reviews<span style={{
                        background: listItemStyle.primaryColor
                    }}></span></Link></li>
                    <li><Link
                        activeClass="active"
                        to="contactus"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >Contact Us<span style={{
                        background: listItemStyle.primaryColor
                    }}></span></Link></li>
                </ul>
            </nav>
        </>
    )
}

export default Nav