import React from 'react'
import {Link} from 'react-router-dom'
import data from '../../data/locData';

function ContactUs() {
    const resrData = data.location
    const listItemStyle = data.settings

    function formatPhoneNumber(phoneNumberString) {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    const fphone = formatPhoneNumber(resrData.phone);

    return (
        <>
            <div id="contactus" className="contactSection displaySection">
                <div className="container">
                    <div className="rowFlex">
                        <div className="address">
                            <h2>Contact Us</h2>
                            <p>Get in touch and let us know how we can help</p>
                            <div className="calling">
                                <div className="iconCallMobile">
                                    <div style={{
                                        background: listItemStyle.primaryColor
                                    }} className="iconCall">
                                        <img src="./img/phoneCall.png" alt="phoneCall"/>
                                    </div>
                                    <h4 style={{
                                        color: listItemStyle.primaryColor
                                    }}>Call Us</h4>
                                </div>
                                <div className="numberCall">
                                    <h4 style={{
                                        color: listItemStyle.primaryColor
                                    }}>Call Us</h4>
                                    <Link to="#">{fphone}</Link>
                                </div>
                            </div>
                            <address style={{
                                background: listItemStyle.contactAddress
                            }}>
                                <h3>Business Hours</h3>
                                <p>{resrData.business_hours}</p>
                            </address>
                        </div>
                        <div className="map">
                            <iframe title={`ContactUs`}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.4952220513596!2d-84.08703188530271!3d33.97981152899214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5bdcf03068a7b%3A0x40f20e106e0ba2b5!2sTru%20by%20Hilton%20Lawrenceville%20Atlanta%20I-85%20Sugarloaf!5e0!3m2!1sen!2sin!4v1665772558020!5m2!1sen!2sin"
                                    frameBorder="0" style={{width: "100%", height: "500px"}}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                            />
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactUs